import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClassGallery } from './class-gallery';

@Injectable({
  providedIn: 'root'
})
export class ServiceGalleryService {

  url = "https://backend.anchoragefieldschools.com/api/view/events";

  constructor(private _http:HttpClient) { }

  getAllGallery():Observable<ClassGallery[]>
  {
    return this._http.get<ClassGallery[]>(this.url);
  }





}
