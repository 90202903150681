
  

    <!--====== Header PART ENDS ======-->

    <!--====== Slider PART START ======-->

    <section class="slider_area slider-active" >
       
        <div class="single_slider bg_cover d-flex align-items-center" style="background-image: url(assets/images/slider-3.jpg)">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="slider_content text-center">
                            <h4 class="sub_title" data-animation="fadeInUp" data-delay="0.2s">Welcome to </h4>
                            <h2 class="main_title" data-animation="fadeInUp" data-delay="0.5s">Anchorage <span>Field</span> Schools</h2>
                            <p data-animation="fadeInUp" data-delay="0.8s">We are a modern Creche, Nursery, Primary and Secondary school in Festac Town, Lagos. <br>  Our teaching approach is very flexible </p>
                            <a class="main-btn bounce" routerLink="/history" data-animation="fadeInUp" data-delay="1.1s">Learn More</a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        


  
    </section>

    <!--====== Slider PART ENDS ======-->

    <!--====== Features PART START ======-->

    <section class="features_area ">
        <div class="container">
            <div class="features_wrapper">
                <div class="row no-gutters">
                    <div class="col-md-4 features_col">
                        <div class="single_features text-center">
                            <div class="features_icon">
                                <img src="assets/images/f-icon-1.png" alt="Icon">
                            </div>
                            <div class="features_content">
                                <h4 class="features_title"><a href="#">Quality Teachers</a></h4>
                                <p> We have teachers focused on helping young people become self-confident individuals.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 features_col">
                        <div class="single_features text-center">
                            <div class="features_icon">
                                <img src="assets/images/f-icon-2.png" alt="Icon">
                            </div>
                            <div class="features_content">
                                <h4 class="features_title"><a href="#">Best Curriculum</a></h4>
                                <p>The Federal Ministry of Education Curriculum is followed to with great level of professionalism.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 features_col">
                        <div class="single_features text-center">
                            <div class="features_icon">
                                <img src="assets/images/f-icon-3.png" alt="Icon">
                            </div>
                            <div class="features_content">
                                <h4 class="features_title"><a href="#">Government Approved</a></h4>
                                <p>We are a government approved institution, WAEC and NECO examination  are taken in AFS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== Features PART ENDS ======-->

    <!--====== About PART START ======-->

    <section class="about_area pt-80">
        <img class="shap_1" src="assets/images/shape/shape-1.png" alt="shape">
        <img class="shap_2" src="assets/images/shape/shape-2.png" alt="shape">
        <img class="shap_3" src="assets/images/shape/shape-3.png" alt="shape">
        <img class="shap_4" src="assets/images/shape/shape-4.png" alt="shape">
        
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about_content mt-45">
                        <h3 class="about_title">Why you should be here?</h3>
                        <h3 class="text">100% Student Focus</h3>
                        <p>Throughout the school, there is a strong focus on individual learning and pupils/students are supported to reach their full potentials in all spheres of life and education</p>
                        <a routerLink="/contact" class="main-btn bounce">Learn More</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about_image mt-50">
                        <img src="assets/images/about-1.jpg" alt="about" class="about_image-1">
                        <img src="assets/images/about-2.jpg" alt="about" class="about_image-2">
                        <img src="assets/images/about-3.jpg" alt="about" class="about_image-3">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== About PART ENDS ======-->

    <!--====== Counter PART START ======-->

    <section class="counter_area pt-80 pb-130">
        <div class="container">
            <div class="row counter_wrapper">
                <div class="col-lg-3 col-sm-6 counter_col">
                    <div class="single_counter text-center mt-50">
                        <div class="counter_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/count_icon-1.png" alt="Icon">
                            </div>
                        </div>
                        <div class="counter_content">
                            <span class="cont"><span class="counter">50</span>+</span>
                            <p>Creche  School</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 counter_col">
                    <div class="single_counter text-center mt-50">
                        <div class="counter_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/count_icon-2.png" alt="Icon">
                            </div>
                        </div>
                        <div class="counter_content">
                            <span class="cont"><span class="counter">100</span>+</span>
                            <p>Primary School</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 counter_col">
                    <div class="single_counter text-center mt-50">
                        <div class="counter_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/count_icon-3.png" alt="Icon">
                            </div>
                        </div>
                        <div class="counter_content">
                            <span class="cont"><span class="counter">200</span>+</span>
                            <p>Secondary School</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 counter_col">
                    <div class="single_counter text-center mt-50">
                        <div class="counter_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/count_icon-4.png" alt="Icon">
                            </div>
                        </div>
                        <div class="counter_content">
                            <span class="cont"><span class="counter">250</span>+</span>
                            <p>JAMB | WAEC | After School Class </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!--====== Counter PART ENDS ======-->



    <!--====== Program PART START ======-->
    <section class="program_area" style="margin-bottom:-120px;padding-bottom:150px;" >

    <section class="program_area pt-120">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section_title section_title_2 text-center pb-50">
                        <h3 class="main_title">Featured Subjects</h3>
                        <p>Anchorage Field Schools offer all the required subjects as required by the Federal Ministry of Education.</p>
                    </div>
                </div>
            </div>
            <div class="row no-gutters program_wrapper">
                <div class="col-lg-4 col-md-6 program_col">
                    <div class="single_program program_color-1 d-flex flex-wrap">
                        <div class="program_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/p-icon-1.png" alt="Icon">
                            </div>
                        </div>
                        <div class="program_content">
                            <div class="content_wrapper">
                                <h5 class="title"><a >Chemistry<br> Physics</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 program_col">
                    <div class="single_program program_color-2 d-flex flex-wrap program_4">
                        <div class="program_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/p-icon-2.png" alt="Icon">
                            </div>
                        </div>
                        <div class="program_content">
                            <div class="content_wrapper">
                                <h5 class="title"><a >Mathematics <br>Further Mathematics</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 program_col">
                    <div class="single_program program_color-3 d-flex flex-wrap program_3">
                        <div class="program_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/p-icon-3.png" alt="Icon">
                            </div>
                        </div>
                        <div class="program_content">
                            <div class="content_wrapper">
                                <h5 class="title"><a >Commerce <br> Economics</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 program_col">
                    <div class="single_program program_color-4 d-flex flex-wrap program_2 program_3 program_4">
                        <div class="program_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/p-icon-4.png" alt="Icon">
                            </div>
                        </div>
                        <div class="program_content">
                            <div class="content_wrapper">
                                <h5 class="title"><a >Accounts <br> Literature</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 program_col">
                    <div class="single_program program_color-5 d-flex flex-wrap program_2">
                        <div class="program_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/p-icon-5.png" alt="Icon">
                            </div>
                        </div>
                        <div class="program_content">
                            <div class="content_wrapper">
                                <h5 class="title"><a >Art & <br> Design</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 program_col">
                    <div class="single_program program_color-6 d-flex flex-wrap program_2 program_4">
                        <div class="program_icon">
                            <div class="icon_wrapper">
                                <img src="assets/images/p-icon-6.png" alt="Icon">
                            </div>
                        </div>
                        <div class="program_content ">
                            <div class="content_wrapper">
                                <h5 class="title"><a >French <br> Yoruba & Igbo</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
   
   
   
   
    </section>
    
  
      
</section>

    <!--====== Program PART ENDS ======-->

   

   


  

 

    