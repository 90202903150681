import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactUser } from './contact-user';

@Injectable({
  providedIn: 'root'
})
export class ContactformService {

  constructor(private _http:HttpClient) { }

  _url="https://backend.anchoragefieldschools.com/api/email";

  contact(contact:ContactUser) {
    return this._http.post<any>(this._url, contact);
  }



  


}
