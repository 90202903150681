
 <section class="page_banner bg_cover" style="background-image: url(assets/images/about_bg.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="banner_content text-center">
                    <h4 class="title">Admission Process</h4>
                    <ul class="breadcrumb justify-content-center">
                        <li><a  routerLinkActive="active"  routerLink="/home">Home</a></li>
                        <li><a routerLinkActive="active" routerLink="/history">Admission</a></li>
                    </ul>
                    <a class="main-btn bounce mt-5" routerLink="/contact" data-animation="fadeInUp" data-delay="1.1s">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="courses_details_area pt-80 pb-130">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 order-lg-last">
                <div class="courses_details_sidebar" style="margin-top: 20px;">
                 
                    <div class="courses_sidebar_title">
                        <h4 class="title">Interview Details Secondary School</h4>
                    </div>
                    <div class="courses_sidebar_list">
                        <ul class="list">
                            <li><i class="fa fa-clock-o"></i> Duration <span>50 minutes</span></li>
                            <li><i class="fa fa-puzzle-piece"></i> Quizzes<span>50</span></li>
                            <li class="text-center font-weight-bold"><i class="fa fa-globe"></i> Subjects</li>

                            <li><i class="fa fa-puzzle-piece"></i>Mathematics</li>
                            <li><i class="fa fa-puzzle-piece"></i>English</li>
                            <li><i class="fa fa-puzzle-piece"></i>General paper(science/commercial)</li>
                        </ul>
                     
                    </div>

                   <div class="courses_sidebar_title">
                       <h4 class="title">Interview Details Primary School</h4>
                   </div>
                   <div class="courses_sidebar_list">
                       <ul class="list">
                           <li><i class="fa fa-clock-o"></i> Duration <span>40 minutes</span></li>
                           <li><i class="fa fa-puzzle-piece"></i> Quizzes<span>30</span></li>
                           <li class="text-center font-weight-bold"><i class="fa fa-globe"></i> Subjects</li>

                           <li><i class="fa fa-puzzle-piece"></i>Mathematics</li>
                           <li><i class="fa fa-puzzle-piece"></i>English</li>
                           
                       </ul>
                    
                   </div>
                </div>
            </div>
            <div class="col-lg-8 order-lg-first">
                <div class="courses_details_content">
                    <div class="single_courses_details  mt-40">
                        <h4 class="courses_details_title">Admission Policy </h4>
                        <p class="text-justify">Our admissions policy is strictly guided by our mission statement. All applicants are appraised on set and selected criteria, irrespective of religion, tribe, nationality and genders.</p>
                    </div>
                    <div class="single_courses_details  mt-40">
                        <h4 class="courses_details_title">Entrance Assessment</h4>
                        <p class="text-justify">Applicants will be invited for assessments as determined by the grade of entrance requirements. The contents of entrance examination is dependent on the grade into which the applicant has applied. Entrance examination into Anchorage Field Schools (nursery and primary) may include test on basic skills, writing, vocabulary and arithmetic. Entrance examination into Anchorage Field Schools Secondary may include test on English, Mathematics and General Studies for first year applicants. Applicants into other levels will be tested on core subjects (Mathematics, English, Business or Science related subjects). Students are expected to perform at average (52%) or above average in order to be granted admission</p>
                    </div>
                    <div class="single_courses_details  mt-40">
                        <h4 class="courses_details_title">Important information when applying to AFS</h4>
                        <ul class="text-justify">
                            <li><i class="fa fa-check"></i>Please provide complete and accurate information about your child’s past educational experience.</li>
                            <li><i class="fa fa-check"></i>Failure to provide school records will delay the processing of application and enrollment.</li>
                            <li><i class="fa fa-check"></i>At least one most recent school years’ records must be provided for all students except pre-nursery students.</li>
                            <li><i class="fa fa-check"></i> For Anchorage Field Schools(secondary) applicant's past two years report sheets may be submitted. This is important for proper class placement./li>
                          
                        </ul>
                    </div>
                   

                
                </div>
            </div>
        </div>
    </div>


    
</section>









<!--====== Why Choose Us PART ENDS ======-->

<!--====== Testimonial PART START ======-->



<section class="counter_area  pb-130" style="   ">
    <div class="container">
        <div class="row counter_wrapper">
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-1.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">50</span>+</span>
                        <p>Creche  School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-2.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">100</span>+</span>
                        <p>Primary School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-3.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">200</span>+</span>
                        <p>Secondary School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-4.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">250</span>+</span>
                        <p>JAMB | WAEC | After School Class </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

