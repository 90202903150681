import { Component } from '@angular/core';
import {fadeAnimation} from './my-animation';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fadeAnimation
  ]
})
export class AppComponent {
  title = 'anchoragefield';

  public currentYear = new Date();
  public yeardate = this.currentYear.getFullYear();
  getState(outlet)
  {
    return outlet.IsActivated ? outlet.activatedRoute.snapshot.url[0].pat:"none";
  }


}
