

 <section class="page_banner bg_cover" style="background-image: url(assets/images/about_bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="banner_content text-center">
                    <h4 class="title">AFS Gallery</h4>
                    <ul class="breadcrumb justify-content-center">
                        <li><a  routerLinkActive="active"  routerLink="/home">Home</a></li>
                        <li><a routerLinkActive="active" routerLink="/gallery">AFS Gallery</a></li>
                    </ul>
                    <a class="main-btn bounce mt-5" routerLink="/contact" data-animation="fadeInUp" data-delay="1.1s">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="program_area" style="margin-bottom:-400px;padding-bottom:100px;" >

    <section class="program_area pt-120" style="padding-bottom:200px">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section_title section_title_2 text-center pb-50">
                        <h3 class="main_title">Event Gallery</h3>
                        <p>Anchorage Field Schools all year round memorable events</p>
                         
                    </div>
                </div>
            </div>

        
        </div>



        <section class="gallery-block cards-gallery" style="margin-top:-100px;padding-bottom:200px;">
            <div class="container">
              
                <div class="row">
                    <div *ngFor="let gallery of allGallery" class="col-md-6 col-lg-4">
                        <div class="card border-0 transform-on-hover">
                            <a class="lightbox" href="{{localhost}}/{{gallery.photo}}" data-caption="Image caption" >
                                <img src="{{localhost}}/{{gallery.photo}}" alt="Card Image" class="card-img-top">
                            </a>
                            <div class="card-body">
                                <h6><a >{{ gallery.category.categoryName }}</a></h6>
                                
                            </div>
                        </div>
                    </div>
                    
              
                
                </div>
            </div>
        </section>



       








   
   
            






   
   
   
    </section>
    
  
      
</section>

