
  <div id="preloader">
    <div class="preloader">
        <span></span>
        <span></span>
    </div>
</div>

<!--====== PRELOADER PART ENDS ======-->

<!--====== Header PART START ======-->
<section class="header_area">
    <div class="header_top">
        <div class="container">
            <div class="header_top_wrapper d-flex justify-content-center justify-content-md-between">
                <div class="header_top_info d-none d-md-block">
                    <ul>
                        <li><img src="../../assets/images/call.png" alt="call"><a >+234-8035829002 , +234-8189801158</a></li>
                        <li><img src="assets/images/mail.png" alt="mail"><a>info@anchoragefieldschools.com</a></li>
                    </ul>
                </div>
                <div class="header_top_login">

                </div>
            </div>
        </div>
    </div>

    <div class="header_menu">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand"  routerLink="/">
                    <img src="../../assets/images/logo.png" style="width: 50px;height: 50px;" alt="logo">

                   <span class="schoolname" style="font-weight:900">&nbsp;&nbsp; Anchorage Field Schools </span>
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li >
                            <a routerLinkActive="active" routerLink="/home"  >Home</a>

                        </li>
                        <li >
                            <a  routerLinkActive="active" routerLink="/history" >History </a>

                        </li>
                        <li>
                            <a routerLink="/admission" routerLinkActive="active" >Admission</a>

                        </li>
                        <li>
                            <a routerLink="/news-info" routerLinkActive="active" >News | Info</a>

                        </li>
                        <li>
                           <!--<a  routerLink="/staff" routerLinkActive="active">Staff</a>-->

                        </li>
                        <li>
                            <a routerLink="/gallery" routerLinkActive="active" >Gallery</a>

                        </li>
                        <li>
                            <a routerLink="/contact" routerLinkActive="active" >Contact</a>

                        </li>


                        <li>
                            <a href="" onclick="return false">School Portal <i class="fa fa-chevron-down"></i></a>

                            <ul class="sub-menu">
                                <li>
                                    <a  href="http://Juniorsec.anchoragefieldschools.com/">Junior-Portal</a>

                                </li>
                                <li>
                                    <a  href="http://seniorsec.anchoragefieldschools.com/" target="_blank" >Senior-Portal</a>

                                </li>
                            </ul>
                        </li>




                    </ul>
                </div>


            </nav>
        </div>
    </div>
</section>

<div   [@routeAnimations] = "getState(outlet)" >
    <router-outlet #outlet="outlet"></router-outlet>
</div>



<!--====== Footer PART START ======-->

<footer class="footer_area bg_cover" style="background-image: url(assets/images/footer_bg.jpg); background-attachment: fixed;">
    <div class="footer_widget pt-80 pb-130">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="footer_about mt-50">
                        <a href="#"><img src="assets/images/logo.png" class="bounce" alt="logo"></a>

                        <p>We are a modern Creche, Nursery, Primary and Secondary school in Festac Town, Lagos. Our teaching approach is very flexible and different from that of a traditional school </p>

                        <ul class="footer_social bounce">
                            <li><a target="_blank" href="https://www.facebook.com/anchoragefieldschools"><i class="fa fa-facebook-f"></i></a></li>
                            <li><a ><i class="fa fa-twitter"></i></a></li>
                            <li><a ><i class="fa fa-instagram"></i></a></li>
                            <li><a ><i class="fa fa-pinterest-p"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="footer_widget_wrapper d-flex flex-wrap">
                        <div class="footer_link mt-50">
                            <h5 class="footer_title">Quick Links</h5>

                            <div class="footer_link_wrapper d-flex">
                                <ul class="link">
                                    <li><a routerLink="/">Home</a></li>
                                    <li><a  routerLink="/history">History</a></li>
                                    <li><a  routerLink="/admission">Admission</a></li>


                                </ul>
                                <ul class="link">
                                    <li><a  routerLink="/staff">Staff</a></li>
                                    <li><a  routerLink="/gallery">Gallery</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="footer_contact mt-50">
                            <h5 class="footer_title">Contact</h5>

                            <ul class="contact">
                                <li>    5th Avenue, House 9, Between B1 & D1 Close,Last Bus Stop
                                    Festac Town, Lagos.
                                    Nigeria.</li>
                                <li><strong>Emal</strong> <br /> info@anchoragefieldschools.com
                                </li>
                                <li> <strong> Phone </strong><br /> +234-8035829002 , +234-8189801158 </li>
                                <li><a target="_blank" href="https://www.google.ng/maps/place/Anchorage+Field+Schools/@6.4739435,3.2751492,17z/data=!3m1!4b1!4m5!3m4!1s0x103b890e74e9f92b:0x97543db76b66dd7d!8m2!3d6.4739382!4d3.2773379?pli=1&source=sign_in_save_to_list">View Location on Google Map</a></li>
                                <li></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer_copyright">
        <div class="container">
            <div class="footer_copyright_wrapper text-center d-md-flex justify-content-between">
                <div class="copyright">
                    <p>Developed By BrandBase</p>
                </div>
                <div class="copyright">
                    <p>&copy; Copyrights {{yeardate}} AFS All rights reserved. </p>
                </div>
            </div>
        </div>
    </div>
</footer>

