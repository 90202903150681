import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewsInfo } from './news-info';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsInfoService {

  constructor(private _httpClient:HttpClient ){ }

  _url ="https://backend.anchoragefieldschools.com/api/news-and-info/view";

  getNewsInfo():Observable<NewsInfo[]>
  {
     return this._httpClient.get<NewsInfo[]>(this._url);

   
  }

  paginate(url):Observable<NewsInfo[]>
  {
    return this._httpClient.get<NewsInfo[]>(url);
  }

  


}
