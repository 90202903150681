import { AppComponent } from './app.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdmissionComponent } from './admission/admission.component'
import { ContactComponent } from './contact/contact.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { NewsInfoComponent } from './news-info/news-info.component';
import { GalleryComponent } from './gallery/gallery.component';

const routes: Routes = [
  {path:'', redirectTo:'/home', pathMatch:'full'},
  {path:'home', component:HomeComponent},
  {path:'history',component:HistoryComponent},
  {path:'admission', component:AdmissionComponent},
  {path:'contact', component:ContactComponent},
  { path: '404', component: PagenotfoundComponent },
  {path:'news-info', component:NewsInfoComponent},
  {path:'gallery',component:GalleryComponent},
  { path: '**', redirectTo: '404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
