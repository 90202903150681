import { Component, OnInit } from '@angular/core';
import { NewsInfo } from './../news-info';
import { NewsInfoService } from './../news-info.service';


@Component({
  selector: 'app-news-info',
  templateUrl: './news-info.component.html',
  styleUrls: ['./news-info.component.scss']
})
export class NewsInfoComponent implements OnInit {

  constructor(private newsInfoService:NewsInfoService) { }

   public newsInfos:NewsInfo[];
   public newsObject = {};
  //====================pagination
    public first_page_url:string;
    public last_page_url:string;
    public next_page_url:string;
    public prev_page_url:string;
    public last_page:number;

  //====================/pagination


   

  ngOnInit(): void {
    
    this.newsInfoService.getNewsInfo().subscribe( 

        (response:NewsInfo[]) => { this.newsInfos = response["data"],
        this.newsObject = response,
        this.first_page_url = response["first_page_url"],
        this.last_page_url = response["last_page_url"],
        this.next_page_url = response["next_page_url"],
        this.prev_page_url = response["prev_page_url"]
        this.last_page = response["last_page"];

      }


      );

    
  }

  pagePage(url)
  {
     this.newsInfoService.paginate(url).subscribe(

          (response:NewsInfo[]) => {
            
            this.newsInfos = response["data"],
            this.newsObject = response,
            this.first_page_url = response["first_page_url"],
            this.last_page_url = response["last_page_url"],
            this.next_page_url = response["next_page_url"],
            this.prev_page_url = response["prev_page_url"],
            this.last_page = response["last_page"],
            window.scrollTo({top:350,  behavior: 'smooth'});
    

          }

     );
  }















}
