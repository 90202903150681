import { Component, OnInit } from '@angular/core';
import { ServiceGalleryService } from './service-gallery.service';
import { ClassGallery } from './class-gallery';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private _serviceGalleryService:ServiceGalleryService) { }

  allGallery:ClassGallery[];
 localhost:string = "http://backend.anchoragefieldschools.com";
 // localhost: string = "http://localhost:8000";
 

  ngOnInit(): void {
   
    this._serviceGalleryService.getAllGallery().subscribe(
      (response:ClassGallery[]) =>{
        this.allGallery = response["data"]
     

      }
    )

  }

}
