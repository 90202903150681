
    <!--====== Page Banner PART START ======-->

    <section class="page_banner bg_cover" style="background-image: url(assets/images/about_bg.jpg)">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner_content text-center">
                        <h4 class="title">Contact Us</h4>
                        <ul class="breadcrumb justify-content-center">
                            <li><a  routerLinkActive="active"  routerLink="/home">Home</a></li>
                            <li><a class="active">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== Page Banner PART ENDS ======-->


    
    <section class="contact_area pt-80 pb-130">
        <div class="services_shape_1" style="background-image: url(assets/images/shape/shape-12.html)"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="contact_form mt-40">                        
                        
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="section_title pb-30">
                                    <h3 class="main_title">Get in touch</h3>
                                    <p>The management of Anchorage Field Schools will be glad to hear from you.</p>
                                </div>
                            </div>
                        </div>
                       <!-- the alert session -->
                        <div *ngIf="submitEvent == 1">
                            <div class="d-flex align-items-center">
                                <strong>Sending your mail dear {{ emailName }}...</strong>
                                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                              </div>
                        </div>

                        <div *ngIf="SuccessMessage != '' ">
                            <div class="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>Success!</strong> {{SuccessMessage}}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                        </div>

                        <div *ngIf="FailureMessage != '' ">
                            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Failure!</strong> {{FailureMessage}}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                        </div>

                        <!-- / end alert session -->

                        <form  (ngSubmit)="onAddItem(f)"   #f="ngForm" >
                 
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="single_form">
                                        <input type="text" 
                                         required
                                         #name="ngModel"
                                          name="name" ngModel placeholder="Name">
                                          <small *ngIf="!name.valid && name.touched" class="text-danger">This value is required</small>
                                    </div> <!-- single form -->
                                </div>
                                <div class="col-md-6">
                                    <div class="single_form">
                                        <input type="email" 
                                        [email]="true"
                                        required
                                        email
                                        #email = "ngModel"
                                         name="email" ngModel  placeholder="Email">
                                        <small *ngIf="!email.valid && email.touched" class="text-danger">This field is required</small>
                                    </div> <!-- single form -->
                                </div>
                                <div class="col-md-12">
                                    <div class="single_form">
                                        <input type="text" 
                                        required
                                        #subject = "ngModel"
                                         name="subject" ngModel placeholder="Subject">
                                         <small *ngIf="!subject.valid && subject.touched" class="text-danger">This field is required</small>
                                    </div> <!-- single form -->
                                </div>
                             
                                <div class="col-md-12">
                                    <div class="single_form">
                                        <textarea placeholder="Massage" 
                                        required
                                        #message = "ngModel"
                                         ngModel name="message"></textarea>
                                         <small class="tex-danger" *ngIf="!message.valid && message.touched">This field is required</small>
                                    </div> <!-- single form -->
                                </div>
                                <div class="col-md-12">
                                    <div class="single_form"   >
                                        <button type="submit" style="border: none;"
                                         class="main-btn bounce"
                                         [disabled]="!f.valid"
                                         >Send Massage</button>
                                    </div> <!-- single form -->
                                </div>
                            </div> <!-- row -->
                        </form>
                    </div> <!-- contact form -->
                </div>
                <div class="col-lg-4">
                    <div class="contact_info pt-20">
                        <ul>
                            <li>
                                <div class="single_info d-flex align-items-center mt-30">
                                    <div class="info_icon">
                                        <i class="fa fa-home"></i>
                                    </div>
                                    <div class="info_content media-body">
                                        <p>
                                            5th Avenue, House 9, Between B1 & D1 Close,Last Bus Stop
                                            Festac Town, Lagos.
                                            Nigeria.
                                        </p>
                                    </div>
                                </div> <!-- single info -->
                            </li>
                            <li>
                                <div class="single_info d-flex align-items-center mt-30">
                                    <div class="info_icon">
                                        <i class="fa fa-phone"></i>
                                    </div>
                                    <div class="info_content media-body">
                                        <p>+234-8035829002 , +234-8189801158</p>
                                    
                                    </div>
                                </div> <!-- single info -->
                            </li>
                            <li>
                                <div class="single_info d-flex align-items-center mt-30">
                                    <div class="info_icon">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="info_content media-body">
                                        <p>info@anchoragefieldschools.com
                                        </p>
                                       
                                    </div>
                                </div> <!-- single info -->
                            </li>
                        </ul>
                    </div> <!-- contact info -->
                    <div class="contact_map mt-50">
                        <div class="gmap_canvas">                            
                            <iframe id="gmap_canvas" src="https://maps.google.com/maps?ll=6.473938200000002,3.2773379&q=Anchorage Field Schools&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>

             
                        </div>
                    </div> <!-- contact map -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </section>

    <!--====== CONTACT PART ENDS ======-->
    