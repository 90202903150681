import { Component, OnInit } from '@angular/core';
import { ContactUser } from './../contact-user';
import { NgForm } from '@angular/forms';
import { ContactformService } from '../contactform.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})


export class ContactComponent implements OnInit {

  constructor(private _contatFormService:ContactformService) { }

  

  ngOnInit(): void {
   
  }


  public newUser:ContactUser;
  public SuccessMessage:string = "";
  public FailureMessage:string = "";
  public submitEvent = 0;
  public emailName:string = "";

  onAddItem(form:NgForm)
  {
  
     this.newUser = form.value;
     this.submitEvent = 1;
     this.emailName = form.value.name;
     
     this._contatFormService.contact(this.newUser)
     .subscribe(
       data => {
         this.SuccessMessage = data.success;
         this.submitEvent = 0;

       },
       error => {
         this.FailureMessage = error.failure;
         this.submitEvent = 0;
        }
     );

      form.reset();


     
  }








}
