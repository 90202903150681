
 <section class="page_banner bg_cover" style="background-image: url(assets/images/about_bg.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="banner_content text-center">
                    <h4 class="title">Our History</h4>
                    <ul class="breadcrumb justify-content-center">
                        <li><a  routerLinkActive="active"  routerLink="/home">Home</a></li>
                        <li><a routerLinkActive="active" routerLink="/history">History</a></li>
                        
                    </ul>
                    <a class="main-btn bounce mt-5" routerLink="/contact" data-animation="fadeInUp" data-delay="1.1s">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--====== Page Banner PART ENDS ======-->

<!--====== About PART START ======-->

<section class="about_area pt-80">
    <img class="shap_1" src="assets/images/shape/shape-1.png" alt="shape">
    <img class="shap_2" src="assets/images/shape/shape-2.png" alt="shape">
    <img class="shap_3" src="assets/images/shape/shape-3.png" alt="shape">
    <img class="shap_4" src="assets/images/shape/shape-4.png" alt="shape">
    
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about_content mt-45">
                    <h3 class="about_title">How it all started</h3>
                    <p class="text text-justify">The journey of Anchorage Field Schools started in 2005 as TSI.The founder saw a need in the education sector which has to do with how students are prepared for external examinations such as JSCE, SSCE(WAEC & NECO Examinations),UTME(JAMB Examination), ICAN, IELTS, TOEFL, SAT etc. and the necessity to adopt a special methodology on how best to prepare students for such examination tasks. This necessitated the name TSI meaning <strong> "THE SPECIALIST INSTITUTE"</strong>

                    </p>
                    <p class="text-justify">In 2005, TSI started with ten teachers at 52 Road, Festac Town, Lagos, Nigeria. TSI was saddled with the responsibility of Organising tutorials for secondary students and also extensively preparing students for external and tertiary intitution examinations.

                        With our great record of success as a tutorial centre, after few years, we yielded to the numerous calls from the beneficiaries of our achievements from far and near to translate this success story into a full school. This brought the birth of a full secondary school called ANCHORAGE FIELD SCHOOLS</p>
                  
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about_image mt-50">
                    <img src="assets/images/about-1.jpg" alt="about" class="about_image-1">
                    <img src="assets/images/madam2.jpg" alt="about" class="about_image-2">
                    <img src="assets/images/madam2.png" alt="about" class="about_image-3">
                </div>
            </div>
        </div>
    </div>
</section>

<!--====== About PART ENDS ======-->

<!--====== Counter PART START ======-->



<!--====== Counter PART ENDS ======-->

<!--====== About 2 PART START ======-->

<section class="about_area_2 d-flex flex-wrap  mt-5">
    <div class="about_video bg_cover" style="background-image: url(assets/images/about_bg.jpg);height:719px;">
        <div class="video">
         
        </div>
     
    </div>
    
    <div class="about_content_2">
        <div class="single_about_2 d-flex flex-wrap about_color_1">
            <div class="about_2_content">
                <div class="about_2_content_wrapper">
                   
                    <p class="text-justify">In 2013, Anchorage Field Schools began. We started with about 30 students(secondary school) and have grown to over 400 pupils and students(nursery, primary and secondary sections).
                        In the future Anchorage Field Schools hopes to extensively implement I.T in students learning process.
                        
                         </p>
                  
                </div>
            </div>
            <div class="about_2_image bg_cover" style="background-image: url(assets/images/about-3.jpg);Height:370px; "></div>
        </div>
        
        <div class="single_about_2 d-flex flex-wrap about_color_2">
            <div class="about_2_content order-md-last">
                <div class="about_2_content_wrapper">
                    <p class="text-justify"> We will continue to grow stronger  by conducting more seminars, improving academic standards as well as producing sports prospects. -Mrs. M.O Hungbo (Founder: Anchorage Field Schools). </p>
                 
                </div>
            </div>
            <div class="about_2_image bg_cover order-md-first" style="background-image: url(assets/images/about-5.jpg)"></div>
        </div>
    </div>
</section>

<!--====== About 2 PART ENDS ======-->

<!--====== Why Choose Us PART START ======-->

<section class="why_choose_area pt-120 pb-130">
 
    <div class="container ">
        <div class="row">
            <div class="col-lg-6">
                <div class="why_choose_content">
                    <div class="section_title pb-20">
                        <h3 class="main_title">Why choose us?</h3>
                        <p><strong>Anchorage Field Schools(AFS)</strong> have since inception been producing the best in academics, socials, culturals and sports.
                            Newspapers, television stations and radio stations can bear witness to few of our success stories.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 pr-50">
                            <div class="single_choose mt-30">
                                <div class="choose_icon">
                                    <img src="assets/images/choose_icon-1.png" alt="Icon">
                                </div>
                                <div class="choose_content">
                                    <h5 class="title"><a href="#">Structure</a></h5>
                                    <p class="text-justify">Although we cannot boast of having the best physical structure (buildings) , surely we can boast of providing a conducive teaching-learning environment, with everything needed for academic, social , cultural and sporting balance for our pupils and students.</p>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-sm-6" style="padding-left:60px;">
                            <div class="single_choose mt-30">
                                <div class="choose_icon">
                                    <img src="assets/images/choose_icon-3.png" alt="Icon">
                                </div>
                                <div class="choose_content">
                                    <h5 class="title"><a >TSI Tutors</a></h5>
                                    <ul style="list-style: none;text-align: justify;">
                                       
                                      
                                        <li> Achieve higher grades if you participate regularly</li>
                                        <li>Tutors do not offer quick fixes by completing a student’s homework, quizzes, or take home tests. Instead they show students how to become independent learners.</li>
                                    </ul>
                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="single_choose mt-30" style="visibility: hidden;">
                                <div class="choose_icon">
                                    <img src="assets/images/choose_icon-4.png" alt="Icon">
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
           
                        <img src="assets/images/choose_bg.png" alt="">
             
                    
           
            </div>
        </div>
    </div>
   
</section>

<!--====== Why Choose Us PART ENDS ======-->

<!--====== Testimonial PART START ======-->



<section class="counter_area  pb-130" style="margin-top: -200px;">
    <div class="container">
        <div class="row counter_wrapper">
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-1.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">50</span>+</span>
                        <p>Creche  School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-2.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">100</span>+</span>
                        <p>Primary School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-3.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">200</span>+</span>
                        <p>Secondary School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-4.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">250</span>+</span>
                        <p>JAMB | WAEC | After School Class </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

