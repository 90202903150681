

 <section class="page_banner bg_cover" style="background-image: url(assets/images/about_bg.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="banner_content text-center">
                    <h4 class="title">News and Information</h4>
                    <ul class="breadcrumb justify-content-center">
                        <li><a  routerLinkActive="active"  routerLink="/home">Home</a></li>
                        <li><a routerLinkActive="active" routerLink="/news-info">News and Info</a></li>
                    </ul>
                    <a class="main-btn bounce mt-5" routerLink="/contact" data-animation="fadeInUp" data-delay="1.1s">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="courses_details_area pt-80 pb-130" name="top">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto order-lg-last">
                

                <div  *ngFor="let info of newsInfos" class="courses_details_sidebar" style="margin-top: 20px;">
                 
                    <div class="courses_sidebar_title">
                        <h4 class="title">Anchorage Field Schools News And Information</h4>
                    </div>
                    <div class="courses_sidebar_list">
                        <ul class="list">
                            <li><i class="fa fa-cog"></i><b> Title</b> <span>{{ info.title }}</span></li>
                            <li><i class="fa fa-puzzle-piece"></i><b> Venue</b><span>{{ info.venue }}</span></li>
                            <li><i class="fa fa-clock-o"></i><b> Date</b><span>{{ info.date | date  }}</span></li>
                            <li class="text-center font-weight-bold"><i class="fa fa-globe"></i> Description</li>
                            <li style="text-align: justify;">
                                
                            </li>

                           
                        </ul>

                        <div>
                            <p style="text-align:justify;">
                                <span style="font-size: 13px" [innerHTML]="info.description "></span>
                                
                            </p>
                            
                        </div>
                     
                    </div>
                
                    
                 
                </div>

                <nav aria-label="..." *ngIf="last_page > 1" >
                    <ul class="pagination"  style="width:100px;">
                      <li class="page-item" title="first page" >
                        <a class="page-link"  (click)= "pagePage(first_page_url)" tabindex="-1" style="padding-bottom:30px;"><i class="fa  fa-fast-backward"></i></a>
                      </li>
                      <li class="page-item" title="previous page" >
                        <a class="page-link" (click)= "pagePage(prev_page_url)" tabindex="-1" style="padding-bottom:30px;"><i class="fa  fa-backward"></i></a>
                      </li>
                    
                    
                      <li class="page-item" title="next page" >
                        <a class="page-link" (click)= "pagePage(next_page_url)" tabindex="-1" style="padding-bottom:30px;"><i class="fa  fa-forward"></i></a>
                      </li>
                      <li class="page-item" title="last page">
                        <a style="padding-bottom:30px;" (click)= "pagePage(last_page_url)"  class="page-link" ><i class="fa  fa-fast-forward"></i></a>
                      </li>
                    </ul>
                </nav>
               

             
               
                
            </div>
         
        </div>
    </div>

    
    
</section>




<section class="counter_area  pb-130" style="   ">
    <div class="container">
        <div class="row counter_wrapper">
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-1.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">50</span>+</span>
                        <p>Creche  School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-2.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">100</span>+</span>
                        <p>Primary School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-3.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">200</span>+</span>
                        <p>Secondary School</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 counter_col">
                <div class="single_counter text-center mt-50">
                    <div class="counter_icon">
                        <div class="icon_wrapper">
                            <img src="assets/images/count_icon-4.png" alt="Icon">
                        </div>
                    </div>
                    <div class="counter_content">
                        <span class="cont"><span class="counter">250</span>+</span>
                        <p>JAMB | WAEC | After School Class </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

