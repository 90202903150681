
    <!--====== Page Banner PART START ======-->

    <section class="page_banner bg_cover" style="background-image: url(assets/images/about_bg.jpg)">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner_content text-center">
                        <h4 class="title">Page Not Found </h4>
                        <ul class="breadcrumb justify-content-center">
                            <li><a  routerLinkActive="active"  routerLink="/home">Home</a></li>
                            <li><a class="active">Page Not Found</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== Page Banner PART ENDS ======-->

    <section>
        <div style="padding:100px">
            <h1 style="height: 200px;font-weight: 900;text-align:center;font-size:80px; ">Page Under Construction</h1>
        </div>
    </section>
